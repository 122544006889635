import { PrismicRichText } from '@prismicio/react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { graphql, Link, PageProps } from 'gatsby';
import { useLottie } from 'lottie-react';
import * as React from 'react';

import Shell from '../components/_layout/shell';
import { InView } from '../components/inView';

import ARROW_LEFT from '../assets/arrow-left.svg';
import ARROW_RIGHT from '../assets/arrow-right.svg';

import * as styles from './index.module.scss';

const variants: Variants = {
  active: { color: '#ff5548', fontSize: '7.2rem' },
  inactive: { color: '#3f3a34', fontSize: '2.5rem' },
};
const arrowVariants: Variants = {
  active: { opacity: 1 },
  inactive: { opacity: 0 },
};

const Animation: React.FC<{ animationData: any }> = ({ animationData }) => {
  const { View, getDuration, playSegments } = useLottie({
    animationData,
    autoplay: false,
    loop: false,
  });

  let dir = 1;
  const loop = React.useCallback(() => {
    const frames = getDuration(true) || 0;
    playSegments(dir === 1 ? [0, frames] : [frames, 0], true);
    dir = dir === 1 ? -1 : 1;
  }, [dir]);

  React.useEffect(() => {
    const duration = getDuration() || 0;
    let interval;

    const dur = duration * 1000;
    const timeout = setTimeout(() => {
      loop();
      interval = setInterval(() => {
        loop();
      }, dur + 2000);
    }, dur);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, []);

  return View;
};

const Index: React.FC<PageProps<Queries.GoAllOutPageQuery>> = ({ data, location }) => {
  const pageData = data.prismicGoAlloutPage?.data;
  const sections = pageData?.sections?.map(s => s?.section?.document) as Queries.PrismicAlloutSection[];
  const hash = location.hash.split('#')[1];
  const index = sections.findIndex(s => s.uid === hash);
  const activeIndex = index === -1 ? 0 : index;
  const active = sections[activeIndex];

  return (
    <Shell>
      <div className={styles.allOut}>
        <div className={styles.header}>
          <nav className={styles.nav}>
            <InView delay={600}>
              <Link to="/" activeClassName={styles.active}>
                Home
              </Link>
            </InView>
            <InView delay={650}>
              <Link to="/work" activeClassName={styles.active}>
                Work
              </Link>
            </InView>
            <InView delay={700}>
              <Link to="/go-all-out" activeClassName={styles.active}>
                Go AllOut
              </Link>
            </InView>
          </nav>
          <InView delay={900} className={styles.headerLogo}>
            AllOut
          </InView>
        </div>
        <div className={styles.wrapper}>
          <section className={styles.list}>
            <div className={styles.links}>
              {sections?.map((s, i) => (
                <InView className={styles.link} key={s.uid} delay={1000 + i * 100} direction="left">
                  <motion.a
                    href={`#${s.uid}`}
                    variants={variants}
                    animate={active?.uid === s.uid ? 'active' : 'inactive'}
                    transition={{
                      type: 'spring',
                      mass: 0.35,
                      stiffness: 75,
                    }}
                  >
                    {s.data.name}
                    <motion.img
                      variants={arrowVariants}
                      animate={active?.uid === s.uid ? 'inactive' : 'active'}
                      className={styles.arrow}
                      src={ARROW_RIGHT}
                      transition={{
                        duration: 0.2,
                      }}
                    />
                  </motion.a>
                </InView>
              ))}
            </div>
          </section>
          <InView delay={1700} className={styles.article}>
            <div className={styles.articleContent}>
              <AnimatePresence mode="wait">
                <motion.article
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{
                    type: 'spring',
                    mass: 0.35,
                    stiffness: 75,
                  }}
                  key={active.uid}
                >
                  <h1 className={styles.articleName}>{active.data.name}</h1>
                  <div className={styles.articleBody}>
                    <PrismicRichText field={active.data.content?.richText} />

                    {active.data.animation_json?.text && (
                      <Animation key={active.data.name} animationData={JSON.parse(active.data.animation_json?.text)} />
                    )}
                    {/* {active.data.animation?.url && (
                      <img className={styles.animation} src={active.data.animation?.url} />
                    )} */}
                  </div>
                </motion.article>
              </AnimatePresence>
            </div>
            <div className={styles.paging}>
              <a href={`#${sections[activeIndex - 1]?.uid}`} data-disabled={activeIndex === 0}>
                <img className={styles.arrow} src={ARROW_LEFT} />
              </a>

              <a href={`#${sections[activeIndex + 1]?.uid}`} data-disabled={activeIndex === sections.length - 1}>
                <img className={styles.arrow} src={ARROW_RIGHT} />
              </a>

              <span>
                {(activeIndex + 1).toString().padStart(2, '0')} / {sections.length.toString().padStart(2, '0')}
              </span>
            </div>
          </InView>
        </div>
      </div>
    </Shell>
  );
};

export const pageQuery = graphql`
  query GoAllOutPage {
    prismicGoAlloutPage {
      data {
        sections {
          section {
            document {
              ... on PrismicAlloutSection {
                uid
                data {
                  name
                  content {
                    richText
                  }
                  animation {
                    url
                  }
                  animation_json {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Index;
